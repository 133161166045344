var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"color":"appBackGround","elevation":"0"}},[_c('v-container',{staticClass:"appBackGround",attrs:{"fluid":""}},[_c('div',{staticClass:"text-center"},[(_vm.loading === true)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(_vm.loading === false)?_c('v-row',{staticClass:"ml-1",attrs:{"wrap":""}},_vm._l((_vm.data),function(item,index){return _c('v-col',{key:index,staticClass:"items-stretch",attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"4"}},[_c('v-card',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{attrs:{"contain":"","src":item.frame_url,"height":"200px"},on:{"click":function($event){return _vm.openImageModal(item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center fill-height"},[_c('v-progress-circular',{attrs:{"color":"grey-lighten-4","indeterminate":""}})],1)]},proxy:true}],null,true)})],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"mb-5"},[_c('div',[_c('span',{staticClass:"mt-1"},[_vm._v("Ref #: ")]),_c('v-chip',{attrs:{"label":"","small":"","round":""}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.hash_id))])])],1),_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"mt-1"},[_vm._v("Student Name: ")]),_c('v-chip',{attrs:{"label":"","small":"","round":""}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.student_name))])])],1)]),_c('v-divider',{staticClass:"my-3"}),_c('div',[_c('v-row',[_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('div',{staticClass:"live_system_content d-flex"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mb-0 font-12"},[_vm._v("Lecture Count")]),_c('span',{staticClass:"ml-2"},[(item.lecture_count > 0)?_c('v-chip',{staticClass:"status-accepted",attrs:{"label":"","small":"","round":""},on:{"click":function($event){return _vm.DetailListing(
                                'lecture',
                                item.hash_id,
                                item.student_name
                              )}}},[_vm._v(_vm._s(item.lecture_count))]):_c('v-chip',{staticClass:"status-disable",attrs:{"small":""}},[_vm._v(_vm._s(item.lecture_count))])],1)]),_c('div',{staticClass:"ml-2 d-flex"},[_c('p',{staticClass:"mb-0 font-12"},[_vm._v("Exam Count:")]),_c('span',{staticClass:"ml-2"},[(item.exam_count > 0)?_c('v-chip',{staticClass:"status-accepted",attrs:{"label":"","small":"","round":""},on:{"click":function($event){return _vm.DetailListing(
                                'exam',
                                item.hash_id,
                                item.student_name
                              )}}},[_vm._v(_vm._s(item.exam_count))]):_c('v-chip',{staticClass:"status-disable",attrs:{"small":""}},[_vm._v(_vm._s(item.exam_count))])],1)])])])],1)],1)],1)],1)],1)}),1):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"700px","max-height":"60vh","overlay-opacity":"0.8"},model:{value:(_vm.imageModal),callback:function ($$v) {_vm.imageModal=$$v},expression:"imageModal"}},[_c('v-carousel',{attrs:{"hide-delimiters":"","reverse":"","show-arrows":_vm.imageArray.length > 1}},_vm._l((_vm.imageArray),function(item,i){return _c('v-carousel-item',{key:i,staticClass:"modal-img",attrs:{"src":item,"active-class":"image-style"}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }