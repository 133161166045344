<template>
  <div>
    <v-card color="appBackGround" class="custom-card" elevation="0">
      <v-card-title class="greyColor--text cus-h font-15 fw-600 pa-6">
        <v-spacer></v-spacer>
        <!-- <div class="pt-0 ml-auto">
          <v-btn
            plain
            small
            @click="clearFilters"
            class="text-decoration-underline text-capitalize"
            v-if="
              status ||
              transaction_type ||
              type ||
              selectedTitle ||
              deleted_transaction
            "
          >
            Clear Filter
          </v-btn>
        </div> -->
        <div class="student-text-field-filter">
          <v-text-field
            v-model="studentName"
            type="text"
            outlined
            hide-details
            dense
            height="30px"
            placeholder="Search by Name"
            prepend-inner-icon="mdi-magnify"
            class="font-12 cus-ml mr-3 fw-500 secondary--text student-search-filters"
          ></v-text-field>
        </div>
        <custom-date-filter
          @typeChanged="typeChanged"
          :type="type"
          @clearSearch="dateCleared"
          @dateChange="dateChanged"
          class="student-text-field-filter mb-1 mr-3"
        >
          <span class="select-date" :class="name != null ? 'black--text' : ''">
            {{ name === null ? "Select Date Range" : name }}
          </span>
          <v-img class="student_calender_icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                fill="grey"
                d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V1m-1 11h-5v5h5v-5Z"
              />
            </svg>
          </v-img>
        </custom-date-filter>
        <v-tooltip bottom v-if="activeGraph === 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="activeGraph === 1"
              color="primary"
              small
              elevation="0"
              height="32px"
              width="32px"
              v-bind="attrs"
              v-on="on"
              tile
              class="mr-3 text-transform-none font-12 fw-400x rounded"
              @click="activeGraph = 0"
            >
              <img src="../../../assets/icons/listView.png" height="24px" />
            </v-btn>
          </template>
          <span> Open In List View</span>
        </v-tooltip>
        <v-tooltip bottom v-if="activeGraph === 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="activeGraph === 0"
              color="primary"
              small
              elevation="0"
              height="32px"
              width="32px"
              v-bind="attrs"
              v-on="on"
              tile
              class="mr-3 text-transform-none font-12 fw-400x rounded"
              @click="activeGraph = 1"
            >
              <img src="../../../assets/icons/layoutView.png" height="24px" />
            </v-btn>
          </template>
          <span> Open In Grid View</span>
        </v-tooltip>
      </v-card-title>
      <DataTable
        v-if="graphTitle === 'list'"
        :customHeaders="headers"
        :data="transactions"
        :loading="isLoading"
        :customSlots="['frame_url', 'lecture_count', 'exam_count']"
        :student="true"
        @groupBy="groupBy"
      >
        <template v-slot:frame_url="{ item }">
          <div class="student-above-image student-thumbnail-image">
            <v-img
              class="student-thumbnail-image"
              :src="item.frame_url"
              alt="face"
              @click="openImageModal(item)"
            ></v-img>
          </div>
        </template>
        <template v-slot:lecture_count="{ item }">
          <div
            v-if="item.lecture_count > 0"
            class="lecture-count cursor-pointer"
            @click="DetailListing('lecture', item.hash_id, item.student_name)"
          >
            {{ item.lecture_count }}
          </div>
          <div v-else>
            {{ item.lecture_count }}
          </div>
        </template>
        <template v-slot:exam_count="{ item }">
          <div
            v-if="item.exam_count > 0"
            class="exam_count cursor-pointer"
            @click="DetailListing('exam', item.hash_id, item.student_name)"
          >
            {{ item.exam_count }}
          </div>
          <div v-else>
            {{ item.exam_count }}
          </div>
        </template>
      </DataTable>
      <div v-if="graphTitle === 'grid'">
        <student-grid-view
          :loading="isLoading"
          :data="JSON.parse(JSON.stringify(transactions))"
        />
      </div>
      <div class="student-pagination-controls">
        <span class="student-show-pagination-numbers"
          >Viewing {{ getViewingRange() }}</span
        >
        <div>
          <v-btn
            @click="prevPage"
            :disabled="paginate.page === 1"
            class="text-capitalize student-pervious-pagination-btn"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.9991 19L9.83911 14C9.56672 13.7429 9.34974 13.433 9.20142 13.0891C9.0531 12.7452 8.97656 12.3745 8.97656 12C8.97656 11.6255 9.0531 11.2548 9.20142 10.9109C9.34974 10.567 9.56672 10.2571 9.83911 10L14.9991 5"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Previous
          </v-btn>
          <v-btn
            @click="nextPageButton"
            :disabled="paginate.page === lastPage"
            class="student-next-pagination-btn ml-2 text-capitalize"
          >
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M4.28257 9.74991C4.17952 9.75196 4.07843 9.71954 3.99327 9.65714C3.90812 9.59473 3.84312 9.50543 3.80727 9.40158C3.77142 9.29774 3.76649 9.18448 3.79317 9.07749C3.81984 8.9705 3.8768 8.87507 3.95616 8.80439L6.99332 6.01018L3.95428 3.21596C3.89876 3.17246 3.85223 3.11707 3.81764 3.05328C3.78304 2.98949 3.76112 2.91868 3.75326 2.84533C3.74541 2.77198 3.75178 2.69767 3.77199 2.6271C3.7922 2.55653 3.82581 2.49123 3.87068 2.43532C3.91555 2.3794 3.97073 2.33409 4.03271 2.30225C4.0947 2.2704 4.16216 2.25271 4.23083 2.25029C4.2995 2.24787 4.36789 2.26077 4.43169 2.28818C4.49548 2.31559 4.5533 2.35692 4.60149 2.40955L8.07575 5.60092C8.13039 5.65108 8.17426 5.71337 8.20434 5.7835C8.23442 5.85363 8.25 5.92993 8.25 6.00715C8.25 6.08438 8.23442 6.16068 8.20434 6.23081C8.17426 6.30094 8.13039 6.36323 8.07575 6.41338L4.60148 9.61483C4.51344 9.69907 4.40042 9.74694 4.28257 9.74991Z"
                fill="white"
              />
            </svg>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-dialog
      v-model="imageModal"
      max-width="700px"
      max-height="60vh"
      overlay-opacity="0.8"
    >
      <v-carousel hide-delimiters reverse :show-arrows="imageArray.length > 1">
        <v-carousel-item
          v-for="(item, i) in imageArray"
          :key="i"
          :src="item"
          active-class="image-style"
          class="modal-img"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
//   import moment from "moment";
import debounce from "lodash.debounce";
import DataTable from "../../common/DataTable.vue";
import ApiService from "@/services/Api";
import CustomDateFilter from "../../common/filters/CustomDateFilter.vue";
import { showErrorMessage } from "../../../utils/showError";
import StudentGridView from "./StudentGridView.vue";
import { ADMIN_API_URL } from "@/utils/env.js";
export default {
  name: "StudentsPortal",
  components: { DataTable, CustomDateFilter, StudentGridView },
  data() {
    return {
      view: "grid",
      imageArray: [],
      sortOn: "created_at",
      sortBy: "desc",
      modalImageUrl: "",
      imageModal: false,
      baseUrl: ADMIN_API_URL,
      name: "All Time",
      dialog: false,
      deleteModal: false,
      deleteReference: "",
      deleteReason: "",
      reasonRule: [
        (value) => !!value || "Required.",
        (value) => (value || "").length <= 250 || "Max 250 characters",
      ],
      btnLoader: false,
      isLoading: false,
      loading: false,
      selectedTitle: "",
      status: null,
      title: null,
      startDate: null,
      endDate: null,
      type: "all_time",
      transaction_type: null,
      studentName: "",
      deleted_transaction: null,
      paginate: {
        page: 1,
        itemsPerPage: 10,
      },
      total: null,
      lastPage: null,
      headers: [
        {
          text: "Student Id",
          align: "start",
          sortable: false,
          value: "hash_id",
          sort_asc: true,
          sort_desc: false,
        },
        {
          text: "Student Name",
          value: "student_name",
          sort_asc: true,
          sort_desc: false,
        },
        { text: "Face", value: "frame_url", key: "face-image" },
        {
          text: "Lecture Count",
          value: "lecture_count",
          key: "lecture-count",
          sort_asc: true,
          sort_desc: false,
        },
        {
          text: "Exam Count",
          value: "exam_count",
          sort_asc: true,
          sort_desc: false,
        },
      ],
      transactions: [],
      statusFilters: [
        { name: "All", value: "all" },
        { name: "Initiated", value: "INITIATED" },
        { name: "Pending", value: "PENDING" },
        { name: "In Process", value: "INPROCESS" },
        { name: "Accepted", value: "ACCEPTED" },
        { name: "Liveness Passed", value: "ACCEPTED" },
        { name: "Declined", value: "DECLINED" },
        { name: "Timeout", value: "TIMEOUT" },
        { name: "Face Not Found", value: "NOT_FOUND" },
      ],
      deletedTransactionFilter: [
        { name: "All", value: "all" },
        { name: "Deleted", value: "true" },
        { name: "Not Deleted", value: "false" },
      ],
      transactionTypeFilter: [
        { name: "All", value: "all" },
        { name: "Liveness", value: "liveness" },
        { name: "Face Match", value: "photo_id_match" },
        { name: "Face Search", value: "face_search" },
        { name: "Face Enrollment", value: "face_enrollment" },
        { name: "Age Estimation", value: "age_estimation" },
      ],
      graphTitle: "grid",
      activeGraph: 1,
    };
  },

  watch: {
    activeGraph(newValue) {
      switch (newValue) {
        case 0:
          this.graphTitle = "list";
          break;
        case 1:
          this.graphTitle = "grid";
          break;
        default:
          break;
      }
    },
    studentName: {
      handler: debounce(function () {
        this.getTransactionsOnPageBasis();
      }, 500),
      deep: true,
    },
    $route: {
      immediate: true, // This will trigger the watcher when the component is initially created.
      handler() {
        if (this.$route.path.includes("student")) {
          this.getTransactions();
        }
      },
    },
    status: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    type: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    transaction_type: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    deleted_transaction: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    paginate: {
      handler() {
        this.getTransactions();
      },
      deep: true,
    },
    sortBy: {
      handler() {
        this.getTransactions();
      },
      deep: true,
    },
  },
  methods: {
    /**
     * Debounces a function, delaying its execution until after a certain amount of time has passed without any additional calls.
     * @param {Function} func - The function to debounce.
     * @param {number} delay - The delay time in milliseconds.
     * @returns {Function} - The debounced function.
     */
    debounce(func, delay) {
      let timeoutId;
      return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
      };
    },

    /**
     * Sets the sorting criteria based on the provided data.
     * @param {Object} data - The data containing sorting criteria (sortBy, sortOn).
     */
    groupBy(data) {
      this.sortBy = data.sortBy;
      this.sortOn = data.sortOn;
    },

    /**
     * Redirects to the corresponding page based on the provided name, ID, and student information.
     * If the name is "lecture", redirects to the lecture page with query parameters.
     * If the name is "exam", redirects to the exam page with query parameters.
     * @param {string} name - The name of the detail listing (e.g., "lecture", "exam").
     * @param {string} id - The ID associated with the detail listing.
     * @param {string} student - The name of the student associated with the detail listing.
     */
    DetailListing(name, id, student) {
      if (name === "lecture") {
        this.$router.push({
          path: "/lecture",
          query: {
            id,
            studentName: student,
            type: this.type,
            start_date: this.start_date,
            end_date: this.end_date,
            name,
            api_type: "single",
          },
        });
      }
      if (name === "exam") {
        this.$router.push({
          path: "/exam",
          query: {
            id,
            studentName: student,
            type: this.type,
            start_date: this.start_date,
            end_date: this.end_date,
            name,
          },
        });
      }
    },

    /**
     * Toggles between grid and list views.
     * @param {string} view - The current view mode ("list" or "grid").
     */
    getView(view) {
      if (view === "list") {
        this.view = "grid";
      } else if (view === "grid") {
        this.view = "list";
      }
    },

    /**
     * Opens the image modal and sets the image array based on the provided item.
     * @param {Object} item - The item containing image data.
     */
    openImageModal(item) {
      this.imageArray = [];
      let img1;
      if (item && item.frame_url) {
        img1 = `${item.frame_url}`;
        this.imageArray.push(img1);
      }
      // Set the dynamic image source for the modal
      this.imageModal = true;
    },

    /**
     * Calculates and returns the viewing range based on pagination.
     * @returns {string} - The viewing range in the format "start out of total".
     */
    getViewingRange() {
      const start = (this.paginate.page - 1) * this.paginate.itemsPerPage + 1;
      const end = Math.min(start + this.paginate.itemsPerPage - 1, this.total);
      return `${end} out of ${this.total}`;
    },

    /**
     * Moves to the next page of transactions if available.
     */
    nextPageButton() {
      if (this.paginate.page < this.lastPage) {
        this.paginate.page += 1;
        this.getTransactions();
      }
    },

    /**
     * Moves to the previous page of transactions if available.
     */
    prevPage() {
      if (this.paginate.page > 1) {
        this.paginate.page -= 1;
        this.getTransactions();
      }
    },

    /**
     * Resets the pagination to the first page and fetches transactions.
     */
    getTransactionsOnPageBasis() {
      if (this.paginate.page !== 1) this.paginate.page = 1;
      else this.getTransactions();
    },

    /**
     * Fetches the content type of the provided URL.
     * @param {string} url - The URL of the content.
     * @returns {Promise<string>} - A promise resolving to the content type.
     */
    getContentType(url) {
      if (url === null) {
        return Promise.resolve(null);
      }
      return fetch(url, { method: "HEAD" })
        .then((response) => {
          const contentType = response.headers.get("Content-Type");
          if (contentType && contentType.startsWith("image/")) {
            return "image";
          } else if (contentType && contentType.startsWith("video/")) {
            return "video";
          } else {
            return "unsupported";
          }
        })
        .catch((error) => {
          console.error("Error fetching the URL:", error);
          return "error";
        });
    },

    /**
     * Fetches transactions based on the current pagination and filters.
     */
    getTransactions() {
      this.isLoading = true;

      let data = {
        pagination: this.paginate.itemsPerPage,
        page: this.paginate.page,
        student_name: this.studentName,
        start_date: this.startDate,
        end_date: this.endDate,
        type: this.type,
        sort_by: this.sortBy,
        sort_on: this.sortOn,
      };
      ApiService.PostRequest("/merchant/student-listing", data)
        .then((response) => {
          this.isLoading = false;
          {
            const returnedData = response.data.result;
            Promise.all(
              returnedData.data.map((item) =>
                this.getContentType(item.quick_liveness_id_url)
              )
            ).then((contentTypes) => {
              this.transactions = returnedData.data.map((item, index) => {
                return {
                  ...item,
                  contentType: contentTypes[index],
                };
              });
            });
            this.total = returnedData.total;
            this.lastPage = returnedData.last_page;
            if (this.graphTitle == "grid") {
              this.activeGraph = 1;
            } else {
              this.activeGraph = 0;
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          showErrorMessage(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
        });
    },

    /**
     * Updates the date range and fetches transactions accordingly.
     * @param {Array<Date>} changedDate - An array containing the start and end dates.
     */
    dateChanged(changedDate) {
      this.type = "custom_range";
      if (changedDate[0] <= changedDate[1]) {
        this.startDate = changedDate[0];
        this.endDate = changedDate[1];
      } else if (changedDate[0] >= changedDate[1]) {
        this.startDate = changedDate[1];
        this.endDate = changedDate[0];
      } else if (changedDate[1] == undefined) {
        this.startDate = changedDate[0];
        this.endDate = "";
      } else {
        (this.startDate = ""), (this.endDate = "");
      }
      this.name = this.startDate + " " + this.endDate;
      this.paginate.page = 1;
      this.getTransactions();
    },

    /**
     * Updates the transaction type and clears the date range, then fetches transactions.
     * @param {Object} type - The selected type object containing value and name.
     */
    typeChanged(type) {
      this.type = type.value;
      this.name = type.name;
      this.startDate = null;
      this.endDate = null;
      this.getTransactionsOnPageBasis();
    },

    /**
     * Clears the applied filters and fetches transactions.
     */
    dateCleared() {
      this.name = null;
      this.type = null;
      this.getTransactionsOnPageBasis();
    },

    /**
     * Clears all filters and fetches transactions accordingly.
     */
    clearFilters() {
      if (this.$route.query.selectedTitle) {
        this.studentName = "";
        this.startDate = null;
        this.endDate = null;
        (this.type = "today"), (this.name = "Today");
        this.paginate.page = 1;
        this.$router.push({
          path: "/student",
        });
        this.getTransactions();
      } else {
        this.studentName = "";
        this.startDate = null;
        this.endDate = null;
        (this.type = "today"), (this.name = "Today");
        this.paginate.page = 1;

        this.getTransactions();
      }
    },
  },
};
</script>

<style>
.modal-img .v-image__image--cover {
  background-size: contain;
}
.modal-img .v-carousel__item {
  background: #e9e9e9;
}
.normal-flag {
  -webkit-transform: scale(0.4) !important;
  transform: scale(0.4) !important;
}
.student_calender_icon {
  padding-top: 5px;
  padding-left: 10px;
}
.detail-btns {
  display: flex;
  justify-content: start;
  align-items: baseline;
}
.icon-background {
  background-color: #f2f4f9 !important;
  border-radius: 15px !important;
  color: #213cc8 !important;
}
.icon-delete {
  color: #ff0505 !important;
  border-radius: 15px !important;
}
.student-above-image {
  display: flex;
}
.student-thumbnail-image {
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  cursor: pointer;
}
.background-color-custom {
  position: relative; /* Ensure relative positioning for absolute positioning inside */
}
.outer-circle {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #eeeef3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.outer-plus-icon {
  position: absolute;
  top: -1px;
  right: -4px;
  height: 8px;
  width: 10px;
  padding: 6px;
  border-radius: 50%;
  background-color: #eeeef3;
  border-top-right-radius: 90%;
  border-bottom-left-radius: 90%;
}
.set-plus-icon {
  position: absolute;
  top: 1px;
  right: 2px;
  height: 8px;
  background-color: #eeeef3;
  border-radius: 50%;
}
.set-age {
  position: relative;
  border: 1.5px solid #213cc8;
  border-radius: 50%;
  padding: 6px;
  background-color: #eeeef3;
  color: #213cc8;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.student-next-pagination-btn {
  border-radius: 6px !important;
  background: #213cc8 !important;
  height: 32px !important;
  padding: 10px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.student-pervious-pagination-btn {
  border-radius: 6px !important;
  border: 1px solid #e3e3e3 !important;
  background: #fff !important;
  height: 32px !important;
  padding: 10px !important;
  color: #0a2540 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.student-pagination-controls {
  display: flex;
  justify-content: space-between;
  padding: 51px 20px 27px 20px;
  align-items: center;
  background: #fafafa;
}
.student-show-pagination-numbers {
  color: #292b30;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.date-time {
  display: flex;
  flex-direction: column;
}
.show-time-created {
  color: #0a2540;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.show-day-created {
  color: #979797;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.student-search-filters-date {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.student-search-filters-date .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
}

.student-search-filters-date::before {
  background-color: transparent !important;
}

.student-search-filters fieldset {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.student-search-filters .v-input__slot {
  min-height: 30px !important;
}
.v-menu__content {
  min-width: 160px !important;
}
.select-filter .v-input__slot {
  width: 120px !important;
}

.student-text-field-filter .v-input__slot {
  width: 165px !important;
}

.student-search-filters .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
  padding-bottom: 8px;
  transform: none !important;
}

.student-search-filters .v-icon::before {
  color: #a0a6b4 !important;
}

.manu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.manu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.manu-custom-class .v-list-item__action {
  margin: 0px;
}

.v-input--selection-controls__input .v-icon {
  font-size: 18px !important;
  border-radius: 6px;
}

.manu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}

.manu-custom-class .v-list-item::before {
  background-color: #ffffff !important;
}

.v-menu__content {
  background: #ffffff !important;
  box-shadow: 0px 4px 14px rgba(137, 144, 162, 0.08);
  border-radius: 6px;
}

.student-search-filters .mdi-chart-line.v-icon {
  font-size: 15px !important;
}
.student-search-filters-deleted .v-input__slot {
  width: 140px !important;
}
.chip-styling {
  font-size: 12px !important;
  font-weight: 500;
}
.cus-h {
  line-height: 3rem !important;
}

@media (max-width: 496px) {
  .cus-ml {
    margin-left: -12px !important;
  }
}
@media (max-width: 390px) {
  .cus-m {
    margin-top: 7px;
  }
}
.deleted-chip {
  font-size: 10px !important;
  font-weight: 700;
  color: #e23b19 !important;
  margin-left: 15px;
}
.type-liveness {
  font-size: 12px !important;
  font-weight: 500;
  color: #4caf50 !important;
  background-color: #edf5ee !important;
}

.type-idscan {
  font-size: 12px !important;
  font-weight: 500;
  color: #7aa0da !important;
  background-color: #edf1f5 !important;
}
.details-header {
  padding-left: 30px !important;
}
.v-btn {
  text-transform: none !important;
}
.status-face-not-found-age {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #96700d !important;
  border-radius: 4px !important;
  background: rgba(238, 208, 128, 0.5) !important;
  padding: 3px 7px;
  font-style: normal !important;
  line-height: normal !important;
  display: inline-block; /* or display: block; */
  width: 104px;
}
.manu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.manu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.manu-custom-class .v-list-item__action {
  margin: 0px;
}

.manu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}
.lecture-count {
  color: #213cc8;
  text-decoration: underline;
}
.exam_count {
  color: #213cc8;
  text-decoration: underline;
}
.custom-card {
  height: 100vh;
  background: #fafafa;
}
</style>
