<template>
  <div>
    <v-card color="appBackGround" elevation="0">
      <v-container class="appBackGround" fluid>
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading === true"
          ></v-progress-circular>
        </div>

        <v-row v-if="loading === false" wrap class="ml-1">
          <v-col
            class="items-stretch"
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            v-for="(item, index) in data"
            :key="index"
          >
            <v-card>
              <div>
                <v-row>
                  <v-col cols="12">
                    <v-img
                      contain
                      :src="item.frame_url"
                      height="200px"
                      @click="openImageModal(item)"
                    >
                      <template v-slot:placeholder>
                        <div
                          class="d-flex align-center justify-center fill-height"
                        >
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                  </v-col>
                </v-row>
              </div>

              <v-card-text>
                <div class="mb-5">
                  <div>
                    <span class="mt-1">Ref #: </span>
                    <v-chip label small round>
                      <span style="font-size: 12px">{{ item.hash_id }}</span>
                    </v-chip>
                  </div>
                  <div class="mt-3">
                    <span class="mt-1">Student Name: </span>
                    <v-chip label small round>
                      <span style="font-size: 12px">{{
                        item.student_name
                      }}</span>
                    </v-chip>
                  </div>
                </div>
                <v-divider class="my-3"></v-divider>
                <div>
                  <v-row>
                    <v-col md="12" sm="12">
                      <div class="live_system_content d-flex">
                        <div class="d-flex">
                          <p class="mb-0 font-12">Lecture Count</p>
                          <span class="ml-2">
                            <v-chip
                              v-if="item.lecture_count > 0"
                              label
                              small
                              round
                              class="status-accepted"
                              @click="
                                DetailListing(
                                  'lecture',
                                  item.hash_id,
                                  item.student_name
                                )
                              "
                              >{{ item.lecture_count }}</v-chip
                            >
                            <v-chip v-else small class="status-disable">{{
                              item.lecture_count
                            }}</v-chip>
                          </span>
                        </div>
                        <div class="ml-2 d-flex">
                          <p class="mb-0 font-12">Exam Count:</p>
                          <span class="ml-2">
                            <v-chip
                              v-if="item.exam_count > 0"
                              label
                              small
                              round
                              class="status-accepted"
                              @click="
                                DetailListing(
                                  'exam',
                                  item.hash_id,
                                  item.student_name
                                )
                              "
                              >{{ item.exam_count }}</v-chip
                            >
                            <v-chip v-else small class="status-disable">{{
                              item.exam_count
                            }}</v-chip>
                          </span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <!-- <v-divider class="my-3"></v-divider> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog
      v-model="imageModal"
      max-width="700px"
      max-height="60vh"
      overlay-opacity="0.8"
    >
      <v-carousel hide-delimiters reverse :show-arrows="imageArray.length > 1">
        <v-carousel-item
          v-for="(item, i) in imageArray"
          :key="i"
          :src="item"
          active-class="image-style"
          class="modal-img"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "StudentGridView",
  components: {},
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
  },
  data() {
    return {
      imageModal: false,
      imageArray: [],
    };
  },
  methods: {
    /**
     * Opens the image modal and sets the imageArray with the frame URL provided by the item object.
     * @param {Object} item - The item containing the frame URL.
     */
    openImageModal(item) {
      this.imageArray = [];
      let img1;
      if (item && item.frame_url) {
        img1 = `${item.frame_url}`;
        this.imageArray.push(img1);
      }
      // Set the dynamic image source for the modal
      this.imageModal = true;
    },

    /**
     * Redirects to the corresponding page based on the provided name, ID, and student information.
     * If the name is "lecture", redirects to the lecture page with query parameters.
     * If the name is "exam", redirects to the exam page with query parameters.
     * @param {string} name - The name of the detail listing (e.g., "lecture", "exam").
     * @param {string} id - The ID associated with the detail listing.
     * @param {string} student - The name of the student associated with the detail listing.
     */
    DetailListing(name, id, student) {
      if (name === "lecture") {
        this.$router.push({
          path: "/lecture",
          query: {
            id,
            studentName: student,
            type: this.type,
            start_date: this.start_date,
            end_date: this.end_date,
            name,
            api_type: "single",
          },
        });
      }
      if (name === "exam") {
        this.$router.push({
          path: "/exam",
          query: {
            id,
            studentName: student,
            type: this.type,
            start_date: this.start_date,
            end_date: this.end_date,
            name,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.count-filter {
  display: grid;
}

.counts {
  display: flex;
  justify-content: end;
  margin-right: 150px;
  height: 20px;
}

.search-filters-date {
  border: 1px solid #dbdde6 !important;
  border-radius: 4px;
}

.search-filters-date .v-icon {
  font-size: 24px !important;
  color: #0000008a !important;
}

.search-filters-date::before {
  background-color: transparent !important;
}

.search-filters fieldset {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.search-filters .v-input__slot {
  min-height: 30px !important;
}

/* .search-filters .v-select__slot {
      height: 30px !important;
    } */
.select-filter .v-input__slot {
  width: 150px !important;
}

.text-field-filter .v-input__slot {
  width: 165px !important;
}

.search-filters .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
  padding-bottom: 8px;
  transform: none !important;
}

.search-filters .v-icon::before {
  color: #a0a6b4 !important;
}

.manu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.custom-select-filter .v-list-item--highlighted {
  background-color: rgb(238, 238, 238) !important;
  color: #c0c3cd !important;
}

.manu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.manu-custom-class .v-list-item__action {
  margin: 0px;
}

.v-input--selection-controls__input .v-icon {
  font-size: 18px !important;
  border-radius: 6px;
}

.manu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}

.v-menu__content {
  background: #ffffff !important;
  box-shadow: 0px 4px 14px rgba(137, 144, 162, 0.08);
  border-radius: 6px;
}

.search-filters .mdi-chart-line.v-icon {
  font-size: 15px !important;
}

.status-pending {
  font-size: 12px !important;
  font-weight: 500;
  color: #c8a135 !important;
  background-color: #faf7ec !important;
}

.status-accepted,
.response-true {
  font-size: 12px !important;
  font-weight: 500;
  color: #213cc8 !important;
  background-color: #82b1ff36 !important;
}
.status-disable {
  font-size: 12px !important;
  font-weight: 500;
  color: grey !important;
  background-color: #c2c7c8 !important;
}

.status-declined {
  font-size: 12px !important;
  font-weight: 500;
  color: #ff784b !important;
  background-color: #c5c3c2 !important;
}

.status-in-process {
  font-size: 12px !important;
  font-weight: 500;
  color: #5ba9fa !important;
  background-color: #f0f5fa !important;
}
.chip-bg {
  font-size: 12px !important;
  font-weight: 500;
  color: #5ba9fa !important;
  background-color: #f3f8ff !important;
}

.info-pair {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* Adjust the margin as needed */
}

.info-label {
  margin-right: 6px;
  white-space: nowrap;
  /* Adjust the margin as needed */
}

.hoverText {
  color: white;
  font-size: 10px;
  font-weight: 600;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  width: max-content;
}

.chip-item {
  margin-right: 10px;
}

.chip-text {
  max-width: 65px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-initiated,
.result-ml {
  font-size: 12px !important;
  font-weight: 500;
  color: #8b7dde !important;
  background-color: #f5f4fc !important;
}

.status-timout {
  font-size: 12px !important;
  font-weight: 500;
  color: #8990a2 !important;
  background-color: #f3f4f5 !important;
}

.result-deo {
  font-size: 12px !important;
  font-weight: 500;
  color: #63ccda !important;
  background-color: #e4f1f1 !important;
}

/* .result-ml {
      font-size: 12px !important;
      font-weight: 500;
      color: #5654d1 !important;
      background-color: #e1e0e9 !important;
    } */
/* .response-true {
      font-size: 12px !important;
      font-weight: 500;
      color: #34dd67 !important;
      background-color: #def1e8 !important;
    } */
.response-false {
  font-size: 12px !important;
  font-weight: 500;
  color: #e23e3e !important;
  background-color: #f1dede !important;
}

.type-liveness {
  font-size: 12px !important;
  font-weight: 500;
  color: #90cc95 !important;
  background-color: #edf5ee !important;
}

.type-idscan {
  font-size: 12px !important;
  font-weight: 500;
  color: #7aa0da !important;
  background-color: #edf1f5 !important;
}

/* .country-chip {
      font-size: 12px !important;
      font-weight: 500;
      color: #836a68 !important;
      background-color: #f4f2f2 !important;
    } */
.v-select {
  width: 100%;

  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1px;
  box-sizing: border-box;
  background-color: #fff;
  color: #333;
  max-height: 40px;
  margin-left: 3%;
}

.v-menu__content {
  max-height: 365px;
  overflow-y: auto;
}

.v-list-item {
  font-size: 12px;
  padding: 2px;
}

.v-select__selection {
  font-size: 12px;
  color: #333;
}

.v-chip {
  /* max-width: 90px !important; */
}

.val-over {
  font-size: 9px;
  word-break: break-all;
  overflow-wrap: break-word;
  text-overflow: clip;
  display: flex;
  flex-wrap: wrap;
  max-width: 150px;
  background: #213cc8;
  color: white;
  border-radius: 16px;
  padding: 5px 8px;
  font-size: 10px;
  font-weight: 500;
}

.card-container {
  height: 300px !important;
  max-height: 300px;
  overflow: scroll;
  padding: 10px 10px;
  /* font-size: 11px; */
}

.font-12 {
  font: 12px !important;
}

.chip-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.chip-item {
  font-size: 9px;
  word-break: break-all;
}

::-webkit-scrollbar {
  display: none;
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.chip-item {
  flex: 1 1 23%;
  /* Adjusted flex properties to ensure at least 4 chips per row */
}
.attack-sub-item {
  padding: 3px 7px;
  /* border: 1px solid red; */
  color: #213cc8 !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px !important;
  background: rgba(33, 60, 200, 0.1) !important;
}

.chip-text {
  font-size: 10px;
  word-break: break-all;
}

.hoverText {
  font-size: 10px;
  word-break: break-all;
}

.info-pair {
  margin-bottom: 4px;
}

.info-label {
  margin-right: 4px;
  font-size: 10px;
}
.over-conatiner {
  width: 100%;
  flex-wrap: wrap;
}
.pagination-controls {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  padding: 51px 20px 27px 20px;
  align-items: center;
}
.show-pagination-numbers {
  color: #292b30;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}
.next-pagination-btn {
  border-radius: 6px !important;
  background: #213cc8 !important;
  height: 32px !important;
  padding: 10px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.pervious-pagination-btn {
  border-radius: 6px !important;
  border: 1px solid #e3e3e3 !important;
  background: #fff !important;
  height: 32px !important;
  padding: 10px !important;
  color: #0a2540 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.pagination-controls {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  padding: 51px 20px 27px 20px;
  align-items: center;
}

/* Responsive styles for smaller screens */
@media (max-width: 485px) {
  .pagination-controls {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
